import React from "react";

import { styled } from "style";

import Figure from "components/Figure";
import ThemeImage from "components/Image";

const Container = styled("div")({
  display: "flex",
  flexWrap: "wrap",
  alignContent: "start",
  width: "100%",
  pr: 3,
  mb: -3,
  "& > *:not([aria-hidden])": {
    ml: 3,
    mb: 3,
  },
  "& > *:first-of-type, & > [aria-hidden] + *": {
    ml: 0,
  },
});

const Image = styled(ThemeImage, {
  forwardProps: ["asset", "alt"],
})({
  borderRadius: "md",
});

const Separator = styled("div")({
  width: "100%",
  height: 0,
});

const Gallery = ({ images, sx }) => {
  const items = React.useMemo(() => {
    return images.reduce(
      ([items, stack], image, i, arr) => {
        const { aspectRatio } = image.image.asset.metadata.dimensions;
        if (aspectRatio < 1 && stack.length + 1 < 2) {
          return i + 1 < arr.length
            ? [[items], stack.concat(renderImage(image))]
            : items.concat(stack, renderImage(image));
        }

        const separator =
          i > 0
            ? React.createElement(Separator, { "aria-hidden": true, key: i++ })
            : null;

        if (aspectRatio > 1) {
          return separator
            ? [items.concat(separator, renderImage(image)), stack]
            : [items.concat(renderImage(image)), stack];
        } else {
          return separator
            ? [items.concat(separator, ...stack, renderImage(image)), []]
            : [items.concat(...stack, renderImage(image)), []];
        }
      },
      [[], []]
    );

    function renderImage({ _key, alt, caption, image }) {
      const { aspectRatio } = image.asset.metadata.dimensions;
      if (caption) {
        return (
          <Figure
            key={_key}
            caption={caption}
            style={{ flex: aspectRatio }}
            // sx={{ width: "100%" }}
          >
            <Image alt={alt} {...image} />
          </Figure>
        );
      }

      return (
        <Image
          key={_key}
          alt={alt}
          style={{ flex: `${aspectRatio} 1 100%` }}
          {...image}
        />
      );
    }
  }, [images]);

  return <Container sx={sx || {}}>{items}</Container>;
};

export default Gallery;
